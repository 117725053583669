define("discourse/plugins/discourse-docs/discourse/components/docs-search", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "discourse-common/lib/debounce"], function (_exports, _component, _object, _runloop, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_obj = {
    classNames: "docs-search",
    debouncedSearch(term) {
      // TODO: Use discouseDebounce when discourse 2.7 gets released.
      const debounceFunc = _debounce.default || _runloop.debounce;
      debounceFunc(this, "onSearch", term, 500);
    },
    onSearchTermChange(term) {
      this.debouncedSearch(term);
    },
    clearSearch() {
      this.set("searchTerm", "");
      this.onSearch("");
    }
  }, (_applyDecoratedDescriptor(_obj, "onSearchTermChange", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onSearchTermChange"), _obj), _applyDecoratedDescriptor(_obj, "clearSearch", [_object.action], Object.getOwnPropertyDescriptor(_obj, "clearSearch"), _obj)), _obj));
});